/* eslint-disable react/no-array-index-key */
import React, { useEffect, useRef, useState } from "react";
import { Box, BoxProps, Heading, Skeleton } from "@chakra-ui/react";

import dynamic from "next/dynamic";
import Link from "next/link";
import { SearchState } from "react-instantsearch-core";
import { SearchResponse } from "../../../types/Responses/SearchResponse";
import useOnScreen from "../../../hooks/useOnScreen";

const GenericProductScroller = dynamic(() => import("../GenericProductScroller"), {
	loading: () => (
		<Box overflow="hidden">
			<Box width="3000px" display="flex" flexDirection="row">
				{Array(7)
					.fill(0)
					.map((_, i) => (
						<Skeleton key={i.toString()} width="210px" height="320px" m={3} ml={0} mr={5} />
					))}
			</Box>
		</Box>
	),
});

type ProductSwimlaneProps = {
	products: SearchResponse[];
	title: string;
	actionText?: string;
	actionSearchBuilder: SearchState;
	containerStyle?: BoxProps;
};

const ProductSwimlane: React.FC<ProductSwimlaneProps> = ({
	products,
	title,
	actionText,
	actionSearchBuilder,
	containerStyle,
}) => {
	const [url, setUrl] = useState<string>();

	const productScrollerRef = useRef<HTMLDivElement | null>(null);
	const productScrollerRefValue = useOnScreen(productScrollerRef);
	const [isProductScrollerRef, setIsProductScrollerRef] = useState(false);

	useEffect(() => {
		import("qs").then((qs) => setUrl(`/Search?${qs.stringify(actionSearchBuilder)}`));
	}, [actionSearchBuilder]);

	useEffect(() => {
		if (!isProductScrollerRef) {
			setIsProductScrollerRef(productScrollerRefValue);
		}
	}, [isProductScrollerRef, productScrollerRefValue]);

	if (products?.length === 0) {
		return null;
	}

	return (
		<Box
			as="section"
			{...containerStyle}
			display="flex"
			flexDirection="column"
			justifyContent="center"
			alignItems="center">
			<Box width="100%" maxW="8xl">
				<Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
					<Heading
						display="inline-block"
						color="black"
						fontSize={{ base: "xl", md: "2xl" }}
						fontWeight="semibold">
						{title}
					</Heading>

					<Link href={url ?? "/Search"}>{actionText ?? "View All"}</Link>
				</Box>

				<Box width="100%" ref={productScrollerRef}>
					{isProductScrollerRef && <GenericProductScroller products={products} />}
				</Box>
			</Box>
		</Box>
	);
};

export default React.memo(ProductSwimlane);
