import { Container, Stack } from "@chakra-ui/react";
import React from "react";
import dynamic from "next/dynamic";

const Footer = dynamic(() => import("./Footer"), {
	loading: () => (
		<Container as="footer" role="contentinfo" py={{ base: "12", md: "16" }}>
			<Stack spacing={{ base: "4", md: "5" }} />
		</Container>
	),
	ssr: false,
});

const FooterWrapper = () => <Footer />;

export default React.memo(FooterWrapper);
