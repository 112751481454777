import React from "react";
import { Box, BoxProps, chakra, Skeleton, Text } from "@chakra-ui/react";

import dynamic from "next/dynamic";
import { TopLevelCategory } from "types/Responses/CategoryResponse";
import HomeNavbar from "../HomeNavbar";

const SearchBoxAutocomplete = dynamic(() => import("../../search/SearchBoxAutocomplete"), {
	ssr: false,
	loading: () => <Skeleton pt={5} height="46px" width="100%" />,
});

type HeroProps = {
	categories: TopLevelCategory[];
};

const Hero: React.FC<BoxProps & HeroProps> = (props) => {
	return (
		<Box
			backgroundImage={{
				base: "linear-gradient(rgba(0, 0, 0, 0.3),rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.1)), url('https://media.buck.cheap/file/buckcheap-company/hero_image.webp')",
				md: "url('https://media.buck.cheap/file/buckcheap-company/hero_image.webp')",
			}}
			backgroundSize="cover"
			backgroundPosition="center">
			<HomeNavbar categories={props.categories} />
			<Box {...props}>
				<Box width="100%" maxWidth="8xl">
					<Box bg="white" p={5} borderRadius="xl" maxWidth="2xl" boxShadow="0 4px 18px 0 #0000004d">
						<chakra.h1
							fontSize={{ base: "1.3rem", lg: "1.6rem" }}
							letterSpacing="tight"
							fontWeight="bold"
							lineHeight="1.2">
							Buck Cheap
						</chakra.h1>

						<Text pt={3} color="black" fontSize={{ base: "sm", md: "lg" }}>
							View transparent price histories on a wide range of products from your favourite
							online retailers.
						</Text>

						<Box pt={5} className="app-container">
							<SearchBoxAutocomplete />
						</Box>
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

export default React.memo(Hero);
