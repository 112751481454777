import { useState, useEffect } from "react";

const useOnScreen = (ref: React.MutableRefObject<HTMLElement | null>) => {
	const [isIntersecting, setIntersecting] = useState(false);
	useEffect(() => {
		const observer = new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting));
		if (ref.current) {
			observer.observe(ref.current);
		}
	}, [ref]);

	return isIntersecting;
};
export default useOnScreen;
