import React from "react";
import dynamic from "next/dynamic";

const AboutUs = dynamic(() => import("./AboutUs"), { ssr: false });

const AboutUsWrapper: React.FC = () => {
	return <AboutUs />;
};

export default React.memo(AboutUsWrapper);
