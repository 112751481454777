import React from "react";
import { Box, Skeleton } from "@chakra-ui/react";

interface Props {
	children: React.ReactNode;
}

const CategoriesContainer: React.FC<Props> = ({ children }) => (
	<Box
		as="section"
		id="category"
		bg="white"
		boxShadow="0 4px 18px 0 #cbcbcb"
		display="flex"
		flexDirection="column"
		justifyContent="center"
		alignItems="center">
		<Box
			display="grid"
			maxW="8xl"
			p={5}
			width="100%"
			gridTemplateColumns={{
				base: "repeat(3,1fr)",
				sm: "repeat(4,1fr)",
				md: "repeat(8,1fr)",
			}}>
			{children}
		</Box>
	</Box>
);

export default CategoriesContainer;
