/* eslint-disable no-await-in-loop */
import React from "react";
import type { NextPage } from "next";
import { Box, Divider, Spinner } from "@chakra-ui/react";

import dynamic from "next/dynamic";
import Footer from "components/Footer";
import { SearchResponseHit } from "typesense/lib/Typesense/Documents";
import { TopLevelCategory } from "../types/Responses/CategoryResponse";
import { SearchResponse } from "../types/Responses/SearchResponse";
import Hero from "../components/homePage/Hero";
import ProductSwimlane from "../components/homePage/ProductSwimlane";
import { Company } from "../types/Responses/CompanyResponse";

import AboutUs from "../components/homePage/AboutUs";

import CategoriesLoader from "../components/homePage/Categories/CategoriesLoader";

const Categories = dynamic(() => import("../components/homePage/Categories"), {
	loading: () => <CategoriesLoader />,
	ssr: false,
});

const Stores = dynamic(() => import("../components/homePage/Stores"), {
	loading: () => <Spinner />,
	ssr: false,
});

type HomePageProps = {
	categories: TopLevelCategory[];
	stores: Company[];

	bestProductPricesToday: SearchResponse[];
	lastweekDate: number;
	yesterdayDate: number;

	latestProducts: SearchResponse[];

	// Together
	randomStoreProducts: SearchResponse[];
	randomStore: Company;

	// Together
	randomCategoryProducts: SearchResponse[];
	randomCategory: TopLevelCategory;

	worstProductsThisWeek: SearchResponse[];

	buyAndSaveProducts: SearchResponse[];

	home: boolean;
};

const Home: NextPage<HomePageProps> = ({
	categories,
	stores,
	bestProductPricesToday,
	latestProducts,
	lastweekDate,
	yesterdayDate,
	randomCategory,
	randomCategoryProducts,
	randomStore,
	randomStoreProducts,
	worstProductsThisWeek,
	buyAndSaveProducts,
}) => {
	return (
		<Box minHeight="100vh" bg="#f4f5f9">
			<Hero
				height={{ sm: "auto", md: "450px" }}
				as="header"
				display="flex"
				flexDirection="column"
				justifyContent="center"
				alignItems="center"
				padding={5}
				paddingTop={{ base: 0, md: 5 }}
				categories={categories}
			/>

			<Categories categories={categories} />

			<Divider mt={12} borderColor="transparent" />

			<ProductSwimlane
				title="Best Prices Today"
				actionText="View all"
				products={bestProductPricesToday}
				actionSearchBuilder={{
					refinementList: { productDealType: ["Best Price", "Good Deal"] },
					range: { modified: { min: yesterdayDate, max: yesterdayDate * 2 } },
				}}
				containerStyle={{ pl: 5, pr: 5 }}
			/>

			<Divider mt={12} borderColor="transparent" />

			<ProductSwimlane
				title="Latest Products"
				actionText="View all"
				products={latestProducts}
				actionSearchBuilder={{ sortBy: "Buck_Cheap/sort/created:desc" }}
				containerStyle={{ pl: 5, pr: 5 }}
			/>

			<Divider mt={12} borderColor="transparent" />

			<AboutUs />

			<Divider mt={12} borderColor="transparent" />

			<ProductSwimlane
				title={`Good Deals from ${randomStore.name}`}
				actionText="View all"
				products={randomStoreProducts}
				actionSearchBuilder={{
					refinementList: {
						productDealType: ["Good Deal", "Best Price"],
						companyName: [randomStore.name],
					},
				}}
				containerStyle={{ pl: 5, pr: 5 }}
			/>

			<Divider mt={12} borderColor="transparent" />

			<ProductSwimlane
				title={`Find The Best Prices in ${randomCategory.name}`}
				actionText="View all"
				products={randomCategoryProducts}
				actionSearchBuilder={{
					refinementList: {
						productDealType: ["Good Deal", "Best Price"],
						category: [randomCategory.name],
					},
				}}
				containerStyle={{ pl: 5, pr: 5 }}
			/>

			<Divider mt={12} borderColor="transparent" />

			<Stores stores={stores} />

			<Divider mt={12} borderColor="transparent" />

			<ProductSwimlane
				title="Worst Prices This Week"
				actionText="View all"
				products={worstProductsThisWeek}
				actionSearchBuilder={{ sortBy: "Buck_Cheap/sort/discount:asc" }}
				containerStyle={{ pl: 5, pr: 5 }}
			/>

			<Divider mt={12} borderColor="transparent" />

			<ProductSwimlane
				title="Promotions"
				actionText="View all"
				products={buyAndSaveProducts}
				actionSearchBuilder={{
					sortBy: "Buck_Cheap/sort/promoDiscountAmountPerItem:desc",
				}}
				containerStyle={{ pl: 5, pr: 5 }}
			/>

			<Divider mt={12} borderColor="transparent" />

			{/* <Box
				bg="white"
				p={5}
				display="flex"
				flexDirection="column"
				justifyContent="center"
				alignItems="center">
				<Box width="100%" maxWidth="8xl">
					<Text>Some FAQ???</Text>
					<Text>how ooften is stuff updated???</Text>
				</Box>
			</Box> */}

			<Footer />
		</Box>
	);
};

const getStaticProps = async (): Promise<{
	props: HomePageProps;
	revalidate: number;
}> => {
	try {
		const API = (await import("../lib/api")).default;
		const SearchClient = (await import("../lib/typesenseClient")).default;
		const dayjs = (await import("dayjs")).default;

		const AMOUNT_TO_FETCH = 10;

		const lastweekDate = dayjs().add(-1, "week").unix();
		const yesterdayDate = dayjs().add(-1, "day").unix();

		// Get Categories
		const { data: categoryData } = await API.get<TopLevelCategory[]>(
			`/Category/GetTopLevelCategories`,
		);

		// Get Stores
		const { data: companyData } = await API.get<Company[]>(`/Company/GetAll`);

		// Best prices today
		const { hits: bestProductPricesTodayData } = await SearchClient.search(
			{
				sort_by: "discount:desc",
				q: "",
				facet_by: "productDealType",
				facet_query: "productDealType: [Good Deal, Best Price]",
				filter_by: `modified:>${yesterdayDate}`,
				query_by: "title",
				limit_hits: AMOUNT_TO_FETCH,
				per_page: AMOUNT_TO_FETCH,
			},
			{ cacheSearchResultsForSeconds: 60 },
		);

		// Latest products
		const { hits: latestProductsData } = await SearchClient.search(
			{
				sort_by: "created:desc",
				q: "",
				query_by: "title",
				limit_hits: AMOUNT_TO_FETCH,
				per_page: AMOUNT_TO_FETCH,
			},
			{ cacheSearchResultsForSeconds: 60 },
		);

		// Random Store
		let randomCompanyProductsData: SearchResponseHit<SearchResponse>[] = [];
		let randomCompany = companyData[0];
		let companyCounter = 0;

		do {
			randomCompany = companyData[Math.floor(Math.random() * companyData.length)];
			const randomCompanyResponse = await SearchClient.search(
				{
					sort_by: "discount:desc",
					q: "",
					facet_by: "productDealType",
					facet_query: "productDealType: [Good Deal, Best Price]",
					filter_by: `companyName: ${randomCompany.name} && modified:>${lastweekDate} && discount:>5`,
					query_by: "title",
					limit_hits: AMOUNT_TO_FETCH,
					per_page: AMOUNT_TO_FETCH,
				},
				{ cacheSearchResultsForSeconds: 60 },
			);

			companyCounter++;
			randomCompanyProductsData =
				randomCompanyResponse.hits! as SearchResponseHit<SearchResponse>[];
		} while (companyCounter <= 10 && randomCompanyProductsData?.length <= 6);

		// Random Category
		let randomCategoryProductsData: SearchResponseHit<SearchResponse>[] = [];
		let randomCategory = categoryData[0];
		let categoryCounter = 0;
		do {
			randomCategory = categoryData[Math.floor(Math.random() * categoryData.length)];
			const randomCategoryResponse = await SearchClient.search(
				{
					sort_by: "discount:desc",
					q: "",
					facet_by: "productDealType",
					facet_query: "productDealType: [Good Deal, Best Price]",
					filter_by: `category: ${randomCategory.name} && modified:>${lastweekDate} && discount:>5`,
					query_by: "title",
					limit_hits: AMOUNT_TO_FETCH,
					per_page: AMOUNT_TO_FETCH,
				},
				{ cacheSearchResultsForSeconds: 60 },
			);

			categoryCounter++;
			randomCategoryProductsData =
				randomCategoryResponse.hits! as SearchResponseHit<SearchResponse>[];
		} while (categoryCounter <= 10 && randomCategoryProductsData?.length <= 6);

		// Worst offenders this week
		const { hits: worstProductsThisWeek } = await SearchClient.search(
			{
				filter_by: `modified:>${lastweekDate}`,
				sort_by: "discount:asc",
				q: "",
				query_by: "title",
				limit_hits: AMOUNT_TO_FETCH,
				per_page: AMOUNT_TO_FETCH,
			},
			{ cacheSearchResultsForSeconds: 60 },
		);

		// Buy and save
		const { hits: buyAndSaveProducts } = await SearchClient.search(
			{
				sort_by: "promoDiscountAmountPerItem:desc",
				q: "",
				query_by: "title",
				limit_hits: AMOUNT_TO_FETCH,
				per_page: AMOUNT_TO_FETCH,
			},
			{ cacheSearchResultsForSeconds: 60 },
		);

		return {
			props: {
				categories: categoryData,
				stores: companyData,
				bestProductPricesToday: bestProductPricesTodayData!.map(
					(x) => x.document,
				) as SearchResponse[],
				latestProducts: latestProductsData!.map((x) => x.document) as SearchResponse[],
				lastweekDate,
				yesterdayDate,
				randomStoreProducts: randomCompanyProductsData!.map((x) => x.document) as SearchResponse[],
				randomStore: randomCompany,
				randomCategoryProducts: randomCategoryProductsData!.map((x) => x.document),
				randomCategory,
				worstProductsThisWeek: worstProductsThisWeek!.map((x) => x.document) as SearchResponse[],
				buyAndSaveProducts: buyAndSaveProducts!.map((x) => x.document) as SearchResponse[],
				home: true,
			},
			revalidate: 3600, // 1 hour
		};
	} catch (error) {
		console.error(error);

		return {
			props: {
				categories: [],
				stores: [],
				bestProductPricesToday: [],
				latestProducts: [],
				lastweekDate: 5999,
				yesterdayDate: 5999,
				randomStoreProducts: [],
				randomStore: { name: "", id: "", imageUrl: "" },
				randomCategoryProducts: [],
				randomCategory: {
					name: "",
					id: "",
					imageUrl: "",
					svgIconUrl: "",
					productCount: "1",
					productCountShort: "2",
				},
				worstProductsThisWeek: [],
				buyAndSaveProducts: [],
				home: true,
			},
			revalidate: 5,
		};
	}
};

export { getStaticProps };

export default Home;
