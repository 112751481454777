import React from "react";
import { Box, Flex, IconButton, Collapse, useDisclosure, Skeleton } from "@chakra-ui/react";
import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";

import { TopLevelCategory } from "types/Responses/CategoryResponse";
import dynamic from "next/dynamic";

const DesktopNav = dynamic(() => import("./DesktopNav"), {
	loading: () => <Skeleton width="411px" height="48px" />,
	ssr: false,
});

const MobileNav = dynamic(() => import("./MobileNav"), {
	loading: () => <Skeleton width="411px" height="48px" />,
	ssr: false,
});

type NavbarProps = {
	categories: TopLevelCategory[];
};

const HomeNavbar: React.FC<NavbarProps> = ({ categories }) => {
	const { isOpen, onToggle } = useDisclosure();

	return (
		<Box as="header" top={0} zIndex="99" bg="transparent" width="full">
			<Flex
				bg="transparent"
				color="gray.600"
				py={{ base: 3, md: 5 }}
				px={{ base: 5 }}
				align="center">
				<Flex
					flex={{ base: 1, md: "auto" }}
					ml={{ base: -2 }}
					display={{ base: "flex", md: "none" }}>
					<IconButton
						onClick={onToggle}
						icon={isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />}
						variant="ghost"
						color="white"
						aria-label="Toggle Navigation"
					/>
				</Flex>

				<Flex flex={{ base: 1 }} justify="center">
					<Flex
						display={{ base: "none", md: "flex" }}
						justify={{ base: "center", md: "end" }}
						maxWidth="8xl"
						width="100%">
						<DesktopNav categories={categories} />
					</Flex>
				</Flex>
			</Flex>

			<Collapse
				in={isOpen}
				animateOpacity
				id="mobile-nav-collapse"
				style={{ position: "absolute", width: "100%" }}>
				<MobileNav />
			</Collapse>
		</Box>
	);
};

export default React.memo(HomeNavbar);
