import React from "react";
import { Box, Skeleton } from "@chakra-ui/react";
import CategoriesContainer from "./CategoriesContainer";

const CategoriesLoader: React.FC = () => (
	<CategoriesContainer>
		{Array(16)
			.fill(0)
			.map((_, i) => (
				<Box
					key={`${i}1`}
					display="flex"
					p={3}
					mt={3}
					mr={1.5}
					ml={1.5}
					width="100%"
					height="100%"
					flexDirection="column"
					justifyContent="center"
					alignItems="center"
					textAlign="center">
					<Skeleton width="50px" height="52px" />
					<Skeleton mt={3} width="50px" height="11px" />
				</Box>
			))}
	</CategoriesContainer>
);

export default CategoriesLoader;
